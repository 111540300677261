import NextImage from '../../image';
import Link from 'next/link';
import { useState } from 'react';
import { Cross } from '../icons';
import { useTranslation } from 'next-i18next';

const Favorites = ({ favoritesData }) => {
    const { t } = useTranslation('home');
    const [tempFavoritesPopup, setTempFavoritesPopup] = useState(false);

    return (
        <section>
            <TempFavoritesPopup t={t} tempFavoritesPopup={tempFavoritesPopup} setTempFavoritesPopup={setTempFavoritesPopup} />
            <div className="container py-8 sm:py-12">
                <h2 className='text-3xl sm:text-4xl mb-4 font-bold mt-4'>{favoritesData?.title}</h2>
                <p className='mb-4 sm:mb-8 text-sm sm:text-base'>{favoritesData?.description}</p>
                <div className='flex flex-col-reverse sm:flex-row gap-4 md:gap-8 text-center sm:text-left'>
                    <div className='relative'>
                        <NextImage className="w-full h-full object-cover" sourceUrl={favoritesData?.backgroundImage?.sourceUrl} alt={favoritesData?.backgroundImage?.altText} width={728} height={549} />
                        <div style={{ backgroundColor: favoritesData?.backgroundOverlayColor || '' }} className='absolute inset-0'>
                            {/* <Link href={favoritesData?.imageLink?.url} passHref>
                                <a target={favoritesData?.imageLink?.target} className="btn btn-primary text-center w-3/4 sm:w-fit absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">{favoritesData?.imageLink?.title}</a>
                            </Link> */}
                            <button onClick={() => setTempFavoritesPopup(true)} className="btn btn-primary text-center w-3/4 sm:w-fit absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                {favoritesData?.imageLink?.title}
                            </button>
                        </div>
                    </div>
                    <div className='flex max-w-full gap-4 md:gap-8 flex-col'>
                        <div className='grid grid-cols-2 gap-4 md:gap-8'>
                            <NextImage sourceUrl={favoritesData?.gridImageTopLeft?.sourceUrl} alt={favoritesData?.gridImageTopLeft?.altText} width={345} height={258} />
                            <NextImage sourceUrl={favoritesData?.gridImageTopRight?.sourceUrl} alt={favoritesData?.gridImageTopRight?.altText} width={345} height={258} />
                        </div>
                        <div className='hidden sm:block'>
                            <NextImage quality={100} sourceUrl={favoritesData?.gridImageBottom?.sourceUrl} alt={favoritesData?.gridImageBottom?.altText} width={728} height={253} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

const TempFavoritesPopup = ({ tempFavoritesPopup, setTempFavoritesPopup, t }) => {
    const editorLink = '/product/map'
    return (
      <>
      {tempFavoritesPopup && (
        <div className="fixed top-0 left-0 z-50 justify-center w-full h-full sm:h-full sm:items-center modal flex current-active transition duration-25 ease-in-out opacity-1">
          <div onClick={() => setTempFavoritesPopup(false)} className="absolute inset-0 bg-black opacity-75"></div>
  
          <div className="flex w-full max-w-6xl max-h-screen p-4">
            <div className="relative p-8 overflow-x-auto bg-white rounded shadow-lg w-full">
  
              <div className="absolute top-0 right-0 z-50 block pt-3 pr-3">
                <Cross onClick={() => setTempFavoritesPopup(false)} circleFill="#7e7e7e" fill="#ffffff" className="cursor-pointer z-50 hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2" width={32} height={32} />
              </div>
  
              <div className="relative">
                <div className="grid grid-flow-row gap-8 lg:gap-12 md:overflow-x-scroll lg:overflow-x-auto md:grid-flow-col md:scroll-snap-x md:grid-rows-1 md:auto-cols-md lg:auto-cols-auto lg:show-scrollbar md:hide-scrollbar md:mask-right lg:mask-none">
                  <ProductPopupItem t={t} link={`${editorLink}${t('home.favorite1.params')}`} src={t('home.favorite1.imagesrc')} />
                  <ProductPopupItem t={t} link={`${editorLink}${t('home.favorite2.params')}`} src={t('home.favorite2.imagesrc')} />
                  <ProductPopupItem t={t} link={`${editorLink}${t('home.favorite3.params')}`} src={t('home.favorite3.imagesrc')} />
                  <ProductPopupItem t={t} link={`${editorLink}${t('home.favorite4.params')}`} src={t('home.favorite4.imagesrc')} />
                </div>
              </div>
  
            </div>
          </div>
  
        </div>
      )}
      </>
    );
};

const ProductPopupItem = ({ title = 'Poster preview', src, link, t }) => (
    (<Link
        href={link}
        passHref
        className="relative flex flex-col flex-grow md:flex-shrink active">

        <div className="w-full p-4 flex justify-center bg-gray-100 md:p-8">
          <div className="flex w-fit h-fit shadow-sm">
            <NextImage sourceUrl={src} altText={title} width={160} height={200} />
          </div>
        </div>
        <div className="flex flex-col flex-grow pt-4 space-y-2 text-center md:space-y-4">
          <button className="w-full max-w-fit mx-auto text-xs sm:text-sm text-center btn btn-primary">{t('favorites.design')}</button>
        </div>

    </Link>)
)

export default Favorites