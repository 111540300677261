import { faAngleLeft, faAngleRight, faQuoteRight, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Slider from "react-slick";
import NextImage from '../../image';
import styles from './Reviews.module.scss';


const Reviews = ({ reviewData }) => {
    const settings = {
        dots: false,
        fade: true,
        arrows: true,
        autoplay: true,
        infinite: true,
        nextArrow: <Arrow icon={faAngleRight} />,
        prevArrow: <Arrow icon={faAngleLeft} />,
        autoplaySpeed: 3000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      };

    return (
        <section className="container flex flex-col sm:flex-row gap-8 justify-between mt-12">
            <div className='w-full sm:w-3/5 m-auto'>
                <div className="mt-4 sm:mt-12 mb-6 flex justify-center">
                    <NextImage sourceUrl="/images/v2/quote.svg" alt="Quote" width={20} height={20} />
                </div>
                <div className='relative lg:mx-12'>
                <Slider {...settings} className={`flex sm:px-8 ${styles.slider}`}>
                    {reviewData?.review?.map((review, i) => (
                        <article key={i} className="text-center px-8 max-w-[28em] mx-auto">
                            <p className='text-sm sm:text-base'>{review.description}</p>
                            <p className='mt-4 font-secundary text-sm sm:text-base'>{review.name}</p>
                        </article>
                    ))}
                </Slider>
                </div>
            </div>
            <div className='hidden sm:block sm:w-2/5'>
                <NextImage sourceUrl={reviewData?.image?.sourceUrl || ''} alt={reviewData?.image?.altText || ''} width={500} height={500} />
            </div>
        </section>
    )
}

const Arrow = ({ icon, onClick }) => (
    <div className='flex z-10' onClick={onClick}>
        <FontAwesomeIcon className='cursor-pointer mt-3' width={20} height={20} icon={icon} />
    </div>
)

export default Reviews