import Image from "next/image";
import React, { useEffect, useRef, useState } from "react";
import SvgInstagram from "../icons/Instagram";

export const InstagramGallery = (props) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [instagramData, setInstagramData] = useState(props.instagramData);
    const [usePagination, setUsePagination] = useState(false);
    const [paginationNextUrl, setPaginationNextUrl] = useState("");
    const [paginationPrevUrl, setPaginationPrevUrl] = useState("");

    useEffect(() => {
        if (props.pagination) {
            setUsePagination(props.pagination);
        }
    }, [props]);

    useEffect(() => {
        if (instagramData !== null) {
            setPaginationNextUrl(instagramData.paging.next);
            setPaginationPrevUrl(instagramData.paging.previous);
        }
    }, [instagramData]);

    const handlePaginationNext = () => {
        fetchInstagramData(paginationNextUrl);
    };

    const handlePaginationPrev = () => {
        fetchInstagramData(paginationPrevUrl);
    };

    if (loading) {
        return <div className="instagram-gallery">Laden...</div>;
    }

    if (error) {
        return (
            <div className="instagram-gallery">
                Er is iets fout gegaan.
            </div>
        );
    }

    return (
        <div className={props.className}>
            {instagramData.data
                .slice(0, props.count)
                .map((item, index) => (
                    <div key={index} className={props.itemClassName}>
                        <a
                            key={index}
                            href={item.permalink}
                            className="instagram-link relative flex group"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <div className="group-hover:bg-gray-200 opacity-0 transition-opacity group-hover:opacity-60 absolute inset-0 z-10">
                            </div>
                            <SvgInstagram width={45} height={45} className="z-20 opacity-0 transition-opacity group-hover:opacity-100 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
                            {item.media_type === "IMAGE" ||
                                item.media_type === "CAROUSEL_ALBUM" ? (
                                // eslint-disable-next-line @next/next/no-img-element
                                <Image
                                    className="object-center aspect-square object-cover"
                                    key={index}
                                    width={400}
                                    height={400}
                                    src={item.media_url}
                                    alt={item.caption}
                                />
                            ) : (
                                <video
                                    src={item.media_url}
                                    className="instagram-image aspect-square object-cover"
                                    key={index}
                                    muted
                                    playsInline
                                    controls={false}
                                >
                                    <source
                                        src={item.media_url}
                                        type="video/mp4"
                                    />
                                    <source
                                        src={item.media_url}
                                        type="video/webm"
                                    />
                                </video>
                            )}
                        </a>
                    </div>
                ))}
            {usePagination && (
                <div className="pagination">
                    {paginationPrevUrl && (
                        <button
                            className="pagination-btn pagination-prev"
                            type="button"
                            onClick={handlePaginationPrev}
                        >
                            Previous
                        </button>
                    )}
                    {paginationNextUrl && (
                        <button
                            className="pagination-btn pagination-next"
                            type="button"
                            onClick={handlePaginationNext}
                        >
                            Next
                        </button>
                    )}
                </div>
            )}
        </div>
    );
};