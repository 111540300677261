import { useTranslation } from "next-i18next";
import { InstagramGallery } from "./InstagramGallery";

const Instagram = ({ instagramData }) => {
    const { t } = useTranslation('home');

    return (
        <>
            {instagramData?.data && (
            <div className="mt-8 sm:mt-12 sm:mb-12 container">
                <h2 className='text-3xl sm:text-4xl mb-8 font-bold sm:text-center'>{t('instagram.title')}</h2>
                <InstagramGallery className="grid grid-cols-2 sm:flex gap-2" itemClassName="w-full sm:w-1/4" instagramData={instagramData} />
            </div>
            )}
        </>
    );
}

export default Instagram;