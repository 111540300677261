import Image from 'next/image'
import { useContext } from 'react';
import NextImage from '../../image';
import { PopupContext } from '../context/PopupContext';
import styles from './About.module.scss';

const About = ({ aboutData }) => {
    const { productPopup, setProductPopup } = useContext(PopupContext);
    return (
        <div className={`relative py-8 sm:py-12 w-full ${styles.wrapper}`}>
            <Image
                alt={aboutData?.backgroundImage?.altText || ''}
                src={aboutData?.backgroundImage?.sourceUrl || ''}
                width={1920}
                height={257}
                quality={100}
                className="w-full h-full absolute inset-0 pointer-events-none object-cover -z-10"
            />
            <div style={{ backgroundColor: aboutData?.backgroundOverlayColor || '' }} className='absolute inset-0 -z-10'>
            </div>
            <div className="container gap-6 sm:gap-12 flex flex-col-reverse sm:flex-row">
                <div className='w-full sm:w-2/5'>
                    <NextImage sourceUrl={aboutData?.image?.sourceUrl} alt={aboutData?.image?.altText} width={470} height={495} />
                </div>
                <div className='w-full sm:w-3/5 my-auto'>
                    <h2 className='text-3xl sm:text-4xl mb-4 font-bold'>{aboutData?.title}</h2>
                    <p className='max-w-[36em] leading-7 text-sm sm:text-base'>{aboutData?.description}</p>
                    <button onClick={() => setProductPopup({ ...productPopup, open: true })} className='btn-text mt-4 mb-4 sm:mb-0 sm:mt-8'>{aboutData?.button}</button>
                </div>
            </div>
        </div>
    )
}

export default About