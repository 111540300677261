import { useContext } from 'react';
import NextImage from '../../image/index';
import { PopupContext } from '../context/PopupContext';

const Hero = ({ heroData }) => {
    const { productPopup, setProductPopup } = useContext(PopupContext);

    return (
        <main className='bg-hero relative flex flex-col-reverse sm:flex-row'>
            {/* After and before on square image */}
            <div className='hidden sm:flex w-full'>
                <NextImage className="w-full object-cover" priority quality={100} width={1920} height={674} altText={heroData?.backgroundImage?.altText} sourceUrl={heroData?.backgroundImage?.sourceUrl} />
            </div>
            <div className='sm:absolute bg-white sm:bg-transparent pt-4 pb-8 sm:pb-0 sm:my-0 sm:top-1/2 transform sm:-translate-y-1/2 sm:-translate-x-[25%] sm:left-[25%] sm:text-white max-w-[26em] container'>
                <h1 className='text-4xl sm:text-3xl sm:max-w-[10em] xl:w-[25rem] lg:text-6xl font-bold leading-tight'>{heroData?.title}</h1>
                <button onClick={() => setProductPopup({...productPopup, open: true})} className='btn-text hidden sm:block mt-4 sm:mt-8'>{heroData?.button}</button>
                <button onClick={() => setProductPopup({...productPopup, open: true})} className='btn-primary sm:hidden mt-4 sm:mt-8'>{heroData?.button}</button>
            </div>
            <div className='sm:hidden flex'>
                <NextImage priority quality={100} width={768} height={674} altText={heroData?.showcaseImage?.altText} sourceUrl={heroData?.showcaseImage?.sourceUrl} />
            </div>
        </main>
    )
}

export default Hero