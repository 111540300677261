import { NextSeo } from "next-seo";
import Head from "next/head";

function Seo(props) {
    const { seoData, langHref = [] } = props;
    const seoObj = seoData?.page?.seo || seoData?.seo || seoData;
    
    let jsonSchema = seoObj?.schema?.raw ? JSON.parse(seoObj?.schema?.raw) : null;
    jsonSchema?.['@graph']?.forEach((item) => {
        if(item.image && !item.image?.url) {
            delete item.image;
        }
        if(item.primaryImageOfPage && !item.primaryImageOfPage?.url) {
            delete item.primaryImageOfPage;
        }
    });

    return <>
        <NextSeo
            title={seoObj?.title}
            description={seoObj?.metaDesc}
            noindex={seoObj?.metaRobotsNoindex !== 'index'}
            nofollow={seoObj?.metaRobotsNofollow !== 'follow'}
            openGraph={{
                url: seoObj?.opengraphUrl || '',
                type: seoObj?.opengraphType || 'website',
                title: seoObj?.opengraphTitle || '',
                description: seoObj?.opengraphDescription || '',
                images: [seoObj?.opengraphImage ? {
                    url: seoObj?.opengraphImage?.sourceUrl,
                    alt: seoObj?.opengraphTitle
                } : ''],
                site_name: 'Where It Happened'
            }}
        />
        <Head>
            <title>{seoObj?.title || "Where It Happened"}</title>
            {langHref?.map((item, index) => (
                <link rel="alternate" hrefLang={item?.language} href={item?.href} key={index} />
            ))}
        </Head>
        {seoObj?.schema?.raw && (
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonSchema) }}
            />
        )}
    </>
}

export default Seo;