import Link from 'next/link';
import NextImage from '../../image';
import styles from './Stars.module.scss';

const Stars = ({ starData }) => {

    return (
        <div className={`relative mt-12`}>
            <div className={`${styles.section} -z-10 absolute inset-0`}>
                <div className={styles.stars}></div>
                <div className={styles.stars2}></div>
                <div className={styles.stars3}></div>
            </div>

            <div className="container z-10 py-12 gap-6 sm:gap-12 flex flex-col-reverse sm:flex-row">
                <div className='w-full sm:w-2/5'>
                <NextImage sourceUrl={starData?.image?.sourceUrl} alt={starData?.image?.altText} width={470} height={495} />
                </div>
                <div className='w-full sm:w-3/5 my-auto'>
                    <div className={`${styles.title} max-w-lg`}>
                        <h2 className='text-3xl sm:text-4xl mb-4 font-bold'>
                            {starData?.title}
                        </h2>
                        <p className="leading-7 text-sm sm:text-base">{starData?.description}</p>
                        <Link className='btn-white btn mt-4 mb-4 sm:mb-0 sm:mt-8' href={starData?.link?.url || ''}>
                            {starData?.button}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Stars