import Layout from "../src/components/Layout";
import Hero from "../src/components/home/Hero";
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Usp from "../src/components/home/Usp";
import About from '../src/components/home/About';
import Reviews from "../src/components/home/Reviews";
import ProductTypes from "../src/components/home/ProductTypes";
import Favorites from '../src/components/home/Favorites';
import { getMenuData } from "../src/datafunctions";
import clients from "../src/components/ApolloClient";
import Seo from "../src/components/seo/Seo";
import Instagram from "../src/components/home/Instagram";
import GET_HOMEPAGE_CONTENT from "../src/queries/get-homepage-content";
import Stars from "../src/components/home/Stars";

export default function Home(props) {
	const { seoData } = props;

	return (
		<Layout menuData={props.menuData} newsletter={true}>
			<Seo seoData={seoData} langHref={props.homePageContent?.page?.langhref?.link} />
			<Hero heroData={props?.homePageContent?.page?.homepage?.hero} />
			<Usp uspData={props?.homePageContent?.page?.homepage?.usp} />
			<ProductTypes productsData={props?.homePageContent?.page?.homepage?.productTypes} />
			<Favorites favoritesData={props?.homePageContent?.page?.homepage?.favorites} />
			<About aboutData={props?.homePageContent?.page?.homepage?.about} />
			<Reviews reviewData={props?.homePageContent?.page?.homepage?.reviews} />
			<Stars starData={props?.homePageContent?.page?.homepage?.stars} />
			<Instagram instagramData={props.instagramData} />
		</Layout>
	)
};

export async function getStaticProps({ locale }) {
	const menuData = await getMenuData(locale);

	let accessToken = process.env.IG_TOKEN;
	if (locale === 'de') {
		accessToken = process.env.IG_DE_TOKEN;
	} else if (locale === 'en-GB') {
		accessToken = process.env.IG_UK_TOKEN;
	}

	const count = 4;
	const url = `https://graph.instagram.com/me/media?fields=media_count,media_type,permalink,media_url,caption&limit=${count}&access_token=${accessToken}`;
	const refreshTokenUrl = `https://graph.instagram.com/refresh_access_token?grant_type=ig_refresh_token&access_token=${accessToken}`;

	const { data } = await clients[locale].query(({
		query: GET_HOMEPAGE_CONTENT,
		variables: {
			slug: "/"
		}
	}));

	await fetch(refreshTokenUrl);

	const instagramData = await fetch(url)
		.then((response) => {
			return response.json();
		})
		.then((data) => {
			if (data.hasOwnProperty("error")) {
				return null;
			} else {
				return data;
			}
		})
		.catch((error) => {
			console.error("Error:", error);
			return null;
		});

	return {
		props: {
			seoData: data?.page?.seo,
			menuData,
			instagramData,
			homePageContent: data,
			...(await serverSideTranslations(locale, ['common', 'home', 'cart']))
		},
	}
};


