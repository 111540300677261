import styles from './ProductTypes.module.scss';
import NextImage from '../../image';
import Link from 'next/dist/client/link';
import React, { useContext } from 'react';
import { PopupContext } from '../context/PopupContext';

const ProductTypes = ({ productsData }) => {
    const { productPopup, setProductPopup } = useContext(PopupContext);

    return (
        <>
            <div className='container py-8 sm:py-12'>
                <h2 className='text-3xl sm:text-4xl sm:text-center font-bold sm:mt-4'>{productsData?.title || ''}</h2>
                <p className='mt-4 max-w-[32em] sm:text-center sm:mx-auto text-sm sm:text-base'>{productsData?.description || ''}</p>
            </div>
            <div className={`beige-light ${styles.imageAfter}`}>
                <div className={`sm:text-center container pb-12`}>
                    <div className={`grid grid-cols-1 sm:grid-cols-3 md:flex md:justify-center sm:flex-row gap-8 sm:gap-12 text-left mb-4`}>
                        {productsData?.products?.map((product, index) => {
                            return (
                                <React.Fragment key={index}>
                                    {index < 3 && (
                                        <ProductDisplay
                                            marginX={index === 1 ? 'mx-0' : 'mx-0'}
                                            alt={product?.image?.altText || ''}
                                            src={product?.image?.sourceUrl || ''}
                                            title={product?.productTitle || ''}
                                            text={product?.productDescription || ''}
                                            link={product?.link?.url || ''}
                                            name={product?.name || ''}
                                        />
                                    )}
                                </React.Fragment>
                            )
                        }
                        )}
                    </div>
                    <button onClick={() => setProductPopup({ ...productPopup, open: true })} className="btn-primary lightgreen mt-4 sm:mt-8 opacity">{productsData?.button}</button>
                </div>
            </div>
        </>
    )
}

const ProductDisplay = ({ marginX, alt, src, title, text, link, name }) => {
    return (
        <article className={`z-10 max-w-[25em] md:max-w-[20em] w-full lg:max-w-[25em] my-0 sm:${marginX}`}>
            <Link href={link} passHref>
                <NextImage
                    altText={alt}
                    src={src}
                    width={400}
                    height={400}
                    sourceUrl={src}
                    quality={100}
                    className="w-full"
                />
                <h3 className='font-bold pt-3 pb-2 text-xl'>{title}</h3>
            </Link>

            <p className='text-sm sm:text-base'>{text}</p>
        </article>
    )
}

export default ProductTypes